import React from 'react';
import ReactDOM from 'react-dom';
import QuickDatePicker from './components/quick_date_picker.js.jsx';
import moment from 'moment';

document.addEventListener('DOMContentLoaded', function() {
  const dateMenuElement = document.querySelector("[data-filter-menu-item='quick-date-picker']");
  
  if (!dateMenuElement) {
    return;
  }
  
  const relativeCalendarUrl = dateMenuElement.getAttribute('data-calendar-url');
  
  const onDayClick = (date) => {
    let selectedDate = moment(date);
    window.location.href = `${relativeCalendarUrl}/day/${selectedDate.format("YYYY/M/D")}`;
  };
  
  ReactDOM.render(
    React.createElement(QuickDatePicker, {
      onDayClick: onDayClick
    }),
    dateMenuElement
  )
});