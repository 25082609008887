// Featured Slider activation javascript
// see simple_slider.js for the actual jquery plugin

namespace('Slzr.Theme.FeaturedSlider', function(exports, top) {
  // import Localist jQuery instance
  var jQuery, $;
  jQuery = $ = modulejs.require('jquery');
  var _ = modulejs.require('underscore');

  $(document).ready(function() {
    // Base options for the slider instances
    var baseOptions = {
      slides:          '.em-carousel-slide', // The name of a slide in the slidesContainer
      swipe:           false,	// Add possibility to Swipe
      magneticSwipe:   false, // Add 'magnetic' swiping. When the user swipes over the screen the slides will attach to the mouse's position
      transition:      "slide", // Accepts "slide" and "fade" for a slide or fade transition
      slideTracker:    true, // Add a UL with list items to track the current slide
      slideTrackerID:  'em-carousel-slideposition', // The name of the UL that tracks the slides
      slideOnInterval: true, // Slide on interval
      interval:        5000, // Interval to slide on if slideOnInterval is enabled
      animateDuration: 1000, // Duration of an animation
      animationEasing: 'easeInOut', // Accepts: linear ease in out in-out snap easeOutCubic easeInOutCubic easeInCirc easeOutCirc easeInOutCirc easeInExpo easeOutExpo easeInOutExpo easeInQuad easeOutQuad easeInOutQuad easeInQuart easeOutQuart easeInOutQuart easeInQuint easeOutQuint easeInOutQuint easeInSine easeOutSine easeInOutSine easeInBack easeOutBack easeInOutBack
      pauseOnHover:    true, // Pause when user hovers the slide container
      useDefaultCSS:   false, // Add default CSS for positioning the slides
      neverEnding:     false // Create a 'neverending/repeating' slider effect.
    };

    $('[data-featured-section="true"]').each(function() {
      var $this = $(this);

      // Set a unique tracker element ID
      var tracker_id = 'em-carousel-slideposition-' + Math.floor(Math.random() * 1000000);
      
      const slideOnInterval = $this.data('autoplay') !== undefined ? $this.data('autoplay') : false;

      var options = _.assign({}, baseOptions, {
        slideTrackerID: tracker_id,
        slideOnInterval: slideOnInterval
      });

      // Initialize slider
      var slider = $this.find('.em-slider');
      slider.simpleSlider(options);

      // Hide scroll options if only 1 slide
      var slideOptions = $this.find('.indicator').length;
      if(slideOptions == 1) {
        $this.find('#' + tracker_id).hide();
        $this.find('[data-featured-section="next-slide"]').hide();
      }

      // Attach click handler to next slide button
      $this.find('[data-featured-section="next-slide"]').data('slider', slider.data('simpleslider')).click(function(event) {
        var slider = $(this).data('slider');
        slider.resetSliderIsStopped();
        slider.nextSlide();
      });
    }); // each featured section
  }); // document ready
}); // namespace
