import React from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";

const QuickDatePicker = ({ footer, onDayClick }) => {
  return (
    <div>
      <DayPicker
        enableOutsideDaysClick
        fixedWeeks
        footer={footer}
        mode="single"
        onDayClick={(date) => onDayClick(date)}
        showOutsideDays
      />
    </div>
  );
}

export default QuickDatePicker;

QuickDatePicker.propTypes = {
  footer: PropTypes.string,
  onDayClick: PropTypes.func
};